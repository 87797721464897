@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

@font-face {
    font-family: 'Nanum Square';
    src: url("./fonts/Nanum/NanumSquareL.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nanum Square';
    src: url("./fonts/Nanum/NanumSquareR.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nanum Square';
    src: url("./fonts/Nanum/NanumSquareB.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Nanum Square';
    src: url("./fonts/Nanum/NanumSquareEB.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

.nanumsquare * {
    font-family: 'Nanum Square', sans-serif;
}

iframe {
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background-color: #141517;
}

/* 스크롤바 */

* {
    scrollbar-width: none;
}

*::-moz-scrollbar-track {
    background-color: #f1f1f1;
}

*::-moz-scrollbar-thumb {
    background-color: #888;
}

*::-moz-scrollbar-thumb:hover {
    background-color: #555;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.fadeIn {
    opacity: 0;
    position: relative;
    top: 20px;
    animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        top: 20px;
    }
    to {
        opacity: 1;
        top: 0;
    }
}


.Modal {
    font-weight: 600;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 45vw;
    height: 25%;
    background-color: #202020;
    border-radius: 15px;
    text-align: center;
}

.Modal .modal {
    position: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Modal span {
    width: 120vw;
}

.Modal .title {
    font-size: 2vw;
}

.Modal .subtitle {
    font-size: 1.5vw;
}

.App {
    width: 100%;
    height: 100vh;
}

.App header {
    text-align: center;
    width: 100%;
    height: 18vh;
}

.wrap {
    top: 0.5vh;
    position: relative;
}

.App .text {
    font-weight: 600;
    font-size: 8vh;
    color: #e0e0e0;
    line-height: 10vh;
}

.text img {
    position: inherit;
    height: 8vh;
    vertical-align: text-top;
    margin: 0 2vh 0 0;
}

.menu {
    margin-top: 2vh;
}

.menu a {
    text-decoration: none;
    margin: 0 15px;
    color: white;
}

.menu a:hover {
    animation: menuHover 0.3s forwards;
}

.menu a.active {
    font-weight: bolder;
    color: #d87989;
}

@keyframes menuHover {
    to {
        color: #d87989;
    }
}

.App .name {
    color: #d87989;
}

.liveContainer {
    width: 90%;
    height: 70vh;
    margin: 0 auto;
    text-align: center;
}

.videoDiv {
    color: white;
    text-align: left;
}

.liveContainer span {
    font-weight: 600;
}

.namefield {
    color: white;
    font-size: 4vh;
}

.islive {
    color: #d87989;
    font-size: 4.5vh;
}

.infoContainer {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 60% 45%;
}

.streamerInfoDiv {
    position: relative;
    left: -10%;
    width: 90%;
    height: auto;
    background-color: #202020;
    overflow-y: scroll;
}

.infoSection {
    color: white;
    text-align: left;
    width: 90%;
    height: auto;
    display: grid;
    grid-template-columns: 6vw calc(100% - 6vw);
    gap: 0.5vw;
    margin: 0 auto;
    place-items: center;
    margin-top: 2%;
}

.liveVideo {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.liveVideo:hover {
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%,
                            rgba(0, 0, 0, 0) 25%,
                            rgba(0, 0, 0, 0) 75%,
                            rgb(0, 0, 0) 100%);
    cursor: pointer;
    opacity: 1;
}

.infoTexts {
    width: calc(100% - 1vw);
    height: auto;
}

.profile {
    width: 6vw;
    height: 6vw;
    border-radius: 6vw;
}

.LiveProfile {
    width: 3vw;
    height: 3vw;
    border-radius: 3vw;
    margin: 0.5vw;
}

.LiveInfo {
    height: 3vw;
    display: grid;
    grid-template-columns: 4vw auto;
    padding: 0.5vw;
}

.LiveTitle {
    height: 3vw;
    margin: 0.5vw 0;
}

.disabled {
    display: none;
}

.LiveTitle p {
    margin: 0;
    font-size: 0.8vw;
}

.LiveTitle p:first-child {
    margin: 0;
    font-size: 1.2vw;
}

.infoField {
    display: grid;
}

.infoName {
    font-size: 2vw;
}

.followers,
.accumulate {
    color: #888;
    font-size: 1.2vw;
    margin: 5px 0;
}

.followersCount,
.accumulateCount {
    color: #fff;
}

.description,
.dDayCounter {
    color: #fff;
    font-size: 1.2vw;
    font-weight: 600;
    width: 90%;
    margin: 0 auto;
    margin-top: 2%;
    text-align: left;
}

.socialLinks {
    color: white;
    width: 80%;
    height: 30%;
    overflow-y: scroll;
    margin: 0 auto;
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5% 0;
}

.socialBlock {
    width: 100%;
    display: grid;
    grid-template-columns: 2vw auto;
    place-items: center;
    cursor: pointer;
}

.socialBlock:hover {
    animation: socialHover 0.3s forwards ease;
}

@keyframes socialHover {
    to {
        color: #d87989;
    }
}

.socialBlock i {
    font-size: 2vw;
}

.socialBlock svg {
    width: 2vw;
    height: 2vw;
}

.socialBlock span {
    font-size: 1.2vw;
}

.lastUpdate {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: white;
    font-size: 0.8vw;
    text-align: left;
    display: block;
    z-index: 999;
}

@media (orientation: portrait) {
    .menu a {
        font-size: 2.5vw;
    }

    @media screen and (max-width: 600px) {
        .menu a {
            font-size: 5vw;
        }
    }
}

@media (orientation: landscape) {
    .menu a {
        font-size: 2.5vh;
    }
}

@media screen and (max-width: 1050px) {
    .Modal .title {
        font-size: 2.5vh;
    }
    
    .Modal .subtitle {
        font-size: 1.8vh;
    }    

    .infoContainer {
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 1fr 1fr;
        margin: 0 auto;
    }

    .streamerInfoDiv {
        position: relative;
        width: 80%;
        height: auto;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
    }

    .infoSection {
        height: auto;
        grid-template-columns: 12vh calc(100% - 12vh);
        gap: 3%;
    }
    
    .profile {
        width: 12vh;
        height: 12vh;
        border-radius: 12vh;
    }

    .LiveProfile {
        width: 6vh;
        height: 6vh;
        border-radius: 6vh;
        margin: 1.5vh;
    }

    .LiveInfo {
        height: 6vh;
        display: grid;
        grid-template-columns: 8vh auto;
        padding: 1.5vh;
    }
    
    .LiveTitle {
        height: 6vh;
        margin: 0.5vw 0;
    }
    
    .LiveTitle p {
        margin: 0;
        font-size: 2vh;
    }
    
    .LiveTitle p:first-child {
        margin: 0;
        font-size: 3vh;
    }

    .infoName {
        font-size: 4vh;
    }

    .col,
    .followers,
    .accumulate {
        font-size: 2.7vh;
        line-height: 2.5vh;
    }

    .description,
    .dDayCounter {
        font-size: 2.5vw;
    }

    .socialBlock {
        width: 100%;
        grid-template-columns: 5vh auto;
    }
    
    .socialBlock i {
        font-size: 5vh;
    }
    
    .socialBlock svg {
        width: 5vh;
        height: 5vh;
    }

    .socialBlock span {
        font-size: 2.5vh;
    }

    .dday {
        font-size: 2.1vh;
    }

    .lastUpdate {
        font-size: 2.1vh;
    }
}

@media screen and (max-width: 850px) {
    .socialLinks {
        width: 60%;
        height: 40%;
        grid-template-columns: auto;
        grid-template-rows: 1fr 1fr;
    }

    .socialBlock {
        width: 100%;
        grid-template-columns: 4.5vh auto;
    }

    .socialBlock i {
        font-size: 4.5vh;
    }
    
    .socialBlock svg {
        width: 4.5vh;
        height: 4.5vh;
    }

    .socialBlock span {
        font-size: 2.5vh;
    }

    .dday {
        font-size: 2.1vh;
    }

    .lastUpdate {
        font-size: 2.1vh;
    }
}

@media screen and (max-width: 600px) {
    .socialBlock {
        height: 28px;
    }

    .text .imgWrap {
        position: relative;
    }
    
    .text .textSpan {
        display: none;
    }

    .infoSection {
        margin-top: 5%;
        height: auto;
        grid-template-columns: 12vh auto;
        gap: 3vh;
    }
    
    .profile {
        width: 12vh;
        height: 12vh;
        border-radius: 12vh;
    }

    .LiveProfile {
        width: 6vh;
        height: 6vh;
        border-radius: 6vh;
    }

    .infoTexts {
        position: relative;
        height: auto;
    }

    .infoName {
        font-size: 5vh;
    }

    .followers,
    .accumulate {
        font-size: 2.2vh;
        line-height: 2vh;
        margin: 0;
    }

    .description,
    .dDayCounter {
        font-size: 3vw;
    }

    .socialLinks {
        width: 70%;
        grid-template-columns: auto;
        grid-template-rows: 1fr 1fr;
    }

    .socialBlock {
        width: 100%;
        grid-template-columns: 3vh auto;
    }
    
    .socialBlock i {
        font-size: 3vh;
    }
    
    .socialBlock svg {
        width: 3vh;
        height: 3vh;
    }

    .socialBlock span {
        font-size: 2vh;
    }

    .dday {
        font-size: 1.6vh;
    }

    .lastUpdate {
        font-size: 1.6vh;
    }
}