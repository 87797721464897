* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.widget {
    position: relative;
    top: -150px;
    width: 100%;
    height: 150px;
    color: white;
    font-size: 50px;
    font-weight: 500;
    line-height: 150px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.9);
}

.widget.show {
    animation: show 5s ease forwards;
}

.nickname {
    color: #fd8eaa;
}

@keyframes show {
    0% {
        top: -150px;
    }
    
    25% {
        top: 0;
    }
     
    75% {
        top: 0;
    }

    100% {
        top: -150px;
    }
}